import { TypePendingPackage } from '@/store/modules/dashboard/types'
import {
  ref,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const modules = 'dashboard'

export default {
  setup() {
    const store = useStore()
    const loading = ref(false)
    const router = useRouter()
    const data = ref([])
    const countPickupDelivery = computed<TypePendingPackage>(() => store.state[modules].pendingPickup)

    const openMonitoring = () => {
      router.push({ name: 'monitoring-package' })
    }

    const goto = (refName: string) => {
      const element = document.getElementById(refName)
      if (element) {
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    }

    return {
      data,
      loading,
      openMonitoring,
      goto,
      countPickupDelivery
    }
  }
}

import { Plugins } from '@capacitor/core'

const { BarcodeScanner } = Plugins

const checkPermission = async () => {
  const status = await BarcodeScanner.checkPermission({ force: true })

  if (status.granted) {
    return true
  }
  return false
}

const prepare = async () => {
  if (await checkPermission()) {
    BarcodeScanner.prepare()
  }
}

const startScan = async () => {
  BarcodeScanner.hideBackground()

  const result = await BarcodeScanner.startScan()
  if (result.hasContent) {
    return result.content
  }

  return ''
}

const stopScan = async () => {
  BarcodeScanner.showBackground()
  BarcodeScanner.stopScan()
}

export {
  startScan,
  prepare,
  stopScan,
  checkPermission
}

import { useStore } from 'vuex'
import {
  ref, onMounted, getCurrentInstance, reactive, computed
} from 'vue'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager

} from 'devextreme-vue/data-grid'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import {
  downloadExcel
} from '@/plugins/report/downloadExcel'

export default {
  components: {
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxLoadPanel,
    DxPager
  },
  emits: ['showDetail'],
  setup(props: any, { emit }: any) {
    const loading = ref(false)
    const app = getCurrentInstance()
    const {
      $toast, $numbers, $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const inputSearch = ref(null)
    const refRefundTable = ref()
    const sendInputSearch = ref('')
    const showHeaderFilter = ref(true)
    const showFilterRow = ref(true)
    const noDataText = ref('Tidak Ada Data')
    const position = ref({ of: '#refund-table-id' })
    const displayMode = ref('full')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const autoExpandAll = ref(true)
    const filters = reactive({
      top: 0,
      skip: 0,
      totalRecords: 0,
      search: sendInputSearch.value,
      filter: `AgenAsalId eq ${toAgentId.value} and StatusPengirimanId eq 3 and LastUpdate le ${moment().add(1, 'month').format('YYYY-MM-DD')} and LastUpdate ge ${moment().format('YYYY-MM-DD')}`,
      select: '&$select=Id,Resi,StatusPengirimanId,CreatedDate,LastUpdate,TotalColly,HpPenerima,HpPengirim,NamaPengirim,NamaPenerima,GrandTotal',
      expand: '&$expand=StatusPengiriman($select=Status,Keterangan,Attribute1,Attribute2),TransJadwalHd($select=KodeJadwal,TanggalAktif,Rit),AgenAsal($select=Nama),AgenTujuan($select=Nama)'
    })
    const data = ref([])
    const getAllData = async () => {
      loading.value = true
      filters.search = sendInputSearch.value
      const response = await deliveryPendingUseCase.getAll(filters)
      // console.log('any reponse', response)

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result.map((x: any) => {
          // x.TotalKoli = x['TransPengirimanDetails@odata.count']
          x.Jadwal = x.TransJadwalHd ? x.TransJadwalHd.KodeJadwal : '-'
          x.NamaAgenTujuan = x.AgenTujuan ? x.AgenTujuan.Nama : '-'
          x.NamaAgenAsal = x.AgenAsal ? x.AgenAsal.Nama : '-'
          x.NamaStatusPengiriman = x.StatusPengiriman ? x.StatusPengiriman.Status : '-'
          x.TanggalJadwalAktif = x.TransJadwalHd?.TanggalAktif
          x.Rit = x.TransJadwalHd?.Rit
          return x
        })
        filters.totalRecords = response.count
      }
      loading.value = false
    }
    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      // getAllData()
    }
    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(refRefundTable.value, 'Paket Refund')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }
    const showDetail = (id: any) => {
      emit('showDetail', id)
    }
    onMounted(() => {
      getAllData()
    })
    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }
    return {
      loading,
      data,
      filters,
      onPage,
      showDetail,
      moment,
      inputSearch,
      sendInputSearch,
      searchData,
      showFilterRow,
      showHeaderFilter,
      noDataText,
      autoExpandAll,
      refRefundTable,
      exportGrids,
      position,
      showNavButtons,
      showInfo,
      showPageSizeSelector,
      pageSizes,
      displayMode
    }
  }
}

import { receiveItemUseCase } from '@/domain/usecase'
import {
  ref, watch, reactive, getCurrentInstance,
  onMounted
} from 'vue'
import { prepare, startScan, stopScan } from '@/plugins/barcodeScanner'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default {
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('') as any
    const result = ref([])
    const option = ref(0)
    const store = useStore()
    const router = useRouter()
    const data = ref([])
    const onLoad = ref(false)
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id ?? '')
    const filters = reactive({
      skip: 0,
      top: 5,
      totalRecords: 0,
      customFilter: '',
      filter: 'StatusPengirimanId in (4,5,6)',
      select: 'Id,Guid,Resi,StatusPengirimanId,ManifestId,AgenAsalId,AgenTujuanId,NamaPengirim,NamaPenerima,TipePengiriman,LastUpdate,HpPengirim,HpPenerima',
      expand: `StatusPengiriman,TransJadwalHd($expand=TransJadwalDetails($filter= agenId eq ${toAgentId.value};$select=WaktuKedatangan);$select=id,KodeJadwal,TanggalAktif,Rit;),AgenAsal($select=Nama),
      AgenTujuan($select=Nama),TransPengirimanDetails($count=true;$select=id)`
    })
    const listOption = ref([
      {
        id: 1,
        name: 'No.Resi'
      },
      {
        id: 2,
        name: 'Kode Label'
      },
      {
        id: 3,
        name: 'Agent Asal'
      },
      {
        id: 4,
        name: 'Agent Tujuan'
      },
      {
        id: 5,
        name: 'Nama Pengirim'
      },
      {
        id: 6,
        name: 'Nama Penerima'
      },
      {
        id: 7,
        name: 'No.Telp Pengirim'
      },
      {
        id: 8,
        name: 'No.Telp Penerima'
      }
    ])

    if (checkPermission(PermissionEnum.FilGuid)) {
      const tempOptions = listOption.value
      tempOptions.unshift(
        {
          id: 0,
          name: 'Kode Unik'
        }
      )
      option.value = 0
      listOption.value = tempOptions
    } else {
      option.value = 1
    }

    const onChangeOption = (item: any) => {
      console.log(item)
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')

      const response = await receiveItemUseCase.getAll(filters)
      if (response.error) {
        if (option.value === 0) {
          $toast.add({
            severity: 'error', detail: 'Kode unik tidak ditemukan', group: 'bc', life: 1500
          })
          store.dispatch('hideLoading')
          return
        }
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else if (option.value === 0 && response.result.length === 1) {
        store.dispatch('hideLoading')
        router.push({ name: 'transaksi-barang-diterima-detail', params: { id: response.result[0].Guid } })
      } else {
        data.value = response.result
        filters.totalRecords = response.count
        onLoad.value = true
      }
      store.dispatch('hideLoading')
    }

    const submitSearch = (search: any = '') => {
      let filter = ''
      const copyFilters = filters
      if (search) {
        switch (option.value) {
          case 0:
            filter = `guid eq ${search.toLowerCase()}`
            copyFilters.top = 0
            copyFilters.filter = ''
            if (search.length !== 36) {
              $toast.add({
                severity: 'error', detail: 'Panjang huruf kode unik harus 36', group: 'bc', life: 3000
              })
              return
            }
            break
          case 1:
            copyFilters.filter = ''
            filter = `Contains(tolower(Resi), '${search.toLowerCase()}')`
            break
          case 2:
            filter = `TransStockLabels/any(x: contains(tolower(x/KodeLabel), '${search.toLowerCase()}'))`
            break
          case 3:
            filter = `Contains(tolower(AgenAsal/Nama), '${search.toLowerCase()}')`
            break
          case 4:
            filter = `Contains(tolower(AgenTujuan/Nama), '${search.toLowerCase()}')`
            break
          case 5:
            filter = `Contains(tolower(NamaPengirim), '${search.toLowerCase()}')`
            break
          case 6:
            filter = `Contains(tolower(NamaPenerima), '${search.toLowerCase()}')`
            break
          case 7:
            filter = `Contains(HpPengirim, '${search.toLowerCase()}')`
            break
          case 8:
            filter = `Contains(HpPenerima, '${search.toLowerCase()}')`
            break
          default:
            filter = ''
            break
        }
        copyFilters.skip = 0
        copyFilters.customFilter = filter
        proccessSubmit()
      } else {
        $toast.add({
          severity: 'error', detail: 'Input pencarian tidak boleh kosong', group: 'bc', life: 1500
        })
      }
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      proccessSubmit()
    }

    const scanBarcode = async () => {
      await prepare()
      const value = await startScan()
      await stopScan()
      // const { BarcodeScanner } = Plugins
      // BarcodeScanner.hideBackground() // make background of WebView transparent
      // document.body.style.background = 'transparent'

      // const value = await BarcodeScanner.startScan() // start scanning and wait for a result
      // if the result has content
      if (value.hasContent) {
        console.log(value.content) // log the raw scanned content
      }
      inputSearch.value = value.content
      await submitSearch(inputSearch)
    }

    return {
      inputSearch,
      result,
      option,
      listOption,
      data,
      filters,
      onLoad,
      scanBarcode,
      onPage,
      onChangeOption,
      submitSearch
    }
  }
}

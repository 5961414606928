import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid lg:grid-cols-2 md:grid-cols-1 gap-4" }
const _hoisted_2 = { class: "bg-white shadow p-4 rounded-md" }
const _hoisted_3 = { class: "font-bold mb-3" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "bg-white shadow p-4 rounded-md" }
const _hoisted_7 = { class: "font-bold mb-3" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, "Jumlah Label: " + _toDisplayString(_ctx.labels.Stock), 1),
      _createVNode(_component_DataTable, {
        value: _ctx.labels.Labels,
        class: "table-og",
        responsiveLayout: "scroll"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { header: "Jenis Label" }, {
            body: _withCtx((slotProps) => [
              _createVNode("p", _hoisted_4, _toDisplayString(slotProps.data.Name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { header: "Warna" }, {
            body: _withCtx((slotProps) => [
              _createVNode("div", {
                class: "w-16 h-7",
                style: { backgroundColor: slotProps.data.Color }
              }, null, 4)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { header: "Jumlah" }, {
            body: _withCtx((slotProps) => [
              _createVNode("p", _hoisted_5, _toDisplayString(slotProps.data.Stock), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("p", _hoisted_7, "Jumlah Stiker: " + _toDisplayString(_ctx.stickers.Stock), 1),
      _createVNode(_component_DataTable, {
        value: _ctx.stickers.Stickers,
        class: "table-og",
        responsiveLayout: "scroll"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { header: "Jenis Stiker" }, {
            body: _withCtx((slotProps) => [
              _createVNode("p", _hoisted_8, _toDisplayString(slotProps.data.Name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { header: "Jumlah" }, {
            body: _withCtx((slotProps) => [
              _createVNode("p", _hoisted_9, _toDisplayString(slotProps.data.Stock), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}
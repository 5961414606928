import {
  computed
} from 'vue'
import { useStore } from 'vuex'

const modules = 'dashboard'

export default {
  setup() {
    const store = useStore()
    const stickers = computed(() => store.state[modules].stockSticker)
    const labels = computed(() => store.state[modules].stockLabel)

    return {
      stickers,
      labels
    }
  }
}
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid'
import { downloadExcel } from '@/plugins/report/downloadExcel'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { filter } from 'lodash'

export default {
  name: 'PendingTable',
  components: {
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxLoadPanel,
    DxPager

  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers,
      $strInd
    } = app!.appContext.config.globalProperties
    const op = ref()
    const Id = ref(null) as any
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const loading = ref(false)
    const route = useRoute()
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const position = ref({ of: '#pending-table-id' })
    const showHeaderFilter = ref(true)
    const refPendingTable = ref()
    const showFilterRow = ref(true)
    const noDataText = ref('Tidak Ada Data')
    const autoExpandAll = ref(true)
    const displayMode = ref('full')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const dateOp = ref()
    const dateFilter = ref(moment().format('YYYY-MM-DD')) as any
    const dateFilterTemporary = ref(null) as any
    const filters = {
      search: sendInputSearch.value,
      // filter: `AgenAsalId eq ${toAgentId.value} and statusPengirimanId eq 2`,
      expand: '&$expand=TransPengirimanDetails($count=true;$select=id;$expand=KategoriBarang($select=isRefund)), AgenAsal($select=Id,Nama), AgenTujuan($select=Id,Nama)',
      select: '&$select=Id, StatusPengirimanId, ManifestId, TransJadwalHdId, JenisPembayaranId, TransPenerimaanId, AgenAsalId, AgenTujuanId, Resi, LastUpdate, CreatedDate, Attribute1, TotalColly,HpPenerima,HpPengirim,NamaPengirim,NamaPenerima',
      // totalRecords: 0,
      // top: 0,
      // skip: 0
    } as any
    const isRefund = ref(false)
    const data = ref([])
    const showDialogDetail = ref(false)
    const dataForm = ref({})

    const filterTanggal = () => {
      if (dateFilter.value !== null) {
        dateFilterTemporary.value = dateFilter.value
        if (!Array.isArray(dateFilterTemporary.value)) {
          dateFilterTemporary.value = [moment(), moment()]
        }
        return `CreatedDate gt ${moment(dateFilterTemporary.value[0]).format('YYYY-MM-DD')}T00:00:00Z and CreatedDate lt ${moment(dateFilterTemporary.value[1]).format('YYYY-MM-DD')}T23:59:59Z and`
      }
      return `CreatedDate gt ${moment().subtract(3, 'd').format('YYYY-MM-DD')}T00:00:00Z and CreatedDate lt ${moment().format('YYYY-MM-DD')}T23:59:59Z and`
    }

    const getAllData = async () => {
      loading.value = true
      filters.search = sendInputSearch.value
      filters.filter = `${filterTanggal()} AgenAsalId eq ${toAgentId.value} and statusPengirimanId eq 2`
      const response = await deliveryPendingUseCase.getAll(filters)

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        /* eslint-disable array-callback-return */
        data.value = response.result.map((x: any) => {
          // x.TotalKoli = x['TransPengirimanDetails@odata.count']
          // x.Jadwal = x.TransJadwalHd ? x.TransJadwalHd.KodeJadwal : '-'
          x.NamaAgenAsal = x.AgenAsal ? x.AgenAsal.Nama : '-'
          x.NamaAgenTujuan = x.AgenTujuan ? x.AgenTujuan.Nama : '-'
          // x.TanggalJadwalAktif = x.TransJadwalHd.TanggalAktif
          return x
        })
        filters.totalRecords = response.count

        data.value = response.result
        filters.totalRecords = response.count
        loading.value = false
        // store.dispatch('hideLoading')
      }
    }

    const Search = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }

    const onDetail = (val: any) => {
      router.push({
        name: 'transaksi-pengiriman-pending-detail',
        params: {
          id: val
        }
      })
    }
    const showDropDown = (evt: any, item: any) => {
      // console.log(evt, item)
      dataForm.value = item
      op.value.toggle(evt)
    }

    const refund = (val: any) => {
      // console.log('id val', val)
      Id.value = val
      showDialogDetail.value = true
      isRefund.value = true
    }

    const detail = (val: any) => {
      Id.value = val
      showDialogDetail.value = true
      isRefund.value = false
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      // getAllData()
    }

    const hideDialog = () => {
      showDialogDetail.value = false
    }

    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(refPendingTable.value, 'Paket Pending')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }

    const showDateFilter = (evt: any) => {
      console.log(evt)
      console.log(dateOp)
      dateOp.value.toggle(evt)
    }

    const saveFilterDate = (evt: any) => {
      // console.log(dateFilter)
      dateOp.value.toggle(evt)
      filters.tanggalAktif = moment(dateFilter.value).format('YYYY-MM-DD')
      console.log(dateFilter)
      getAllData()
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      dateOp.value.hide()
      getAllData()
    }

    onMounted(() => {
      // store.dispatch('showLoading')
      getAllData()
    })
    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }
    return {
      store,
      filters,
      data,
      inputSearch,
      moment,
      onDetail,
      router,
      route,
      Search,
      showDialogDetail,
      op,
      showDropDown,
      isRefund,
      dataForm,
      refund,
      detail,
      hideDialog,
      getAllData,
      Id,
      onPage,
      loading,
      sendInputSearch,
      searchData,
      showHeaderFilter,
      noDataText,
      showFilterRow,
      autoExpandAll,
      exportGrids,
      refPendingTable,
      position,
      showNavButtons,
      showInfo,
      showPageSizeSelector,
      pageSizes,
      displayMode,
      showDateFilter,
      saveFilterDate,
      clearFilterDate,
      dateOp,
      dateFilter,
    }
  }
}

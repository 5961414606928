import { receiveItemUseCase } from '@/domain/usecase'
import {
  ref, onMounted, getCurrentInstance, reactive, computed
} from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import DxButton from 'devextreme-vue/button'
import { downloadExcel } from '@/plugins/report/downloadExcel'

export default {
  components: {
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxButton,
    DxLoadPanel,
    DxPager
  },
  props: {
    type: {
      default: 'received'
    }
  },
  emits: ['submitDropAgent', 'submitHandoverItem', 'showDetail'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast, $numbers, $strInd
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const type = computed(() => propertiesProps.isShowDialog) as any
    const store = useStore()
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const data = ref([])
    const dt = ref()
    const refTableReceiveItem = ref()
    const loading = ref(false)
    const inputSearch = ref('')
    const sendInputSearch = ref('')
    const showHeaderFilter = ref(true)
    const position = ref({ of: '#receive-item-table-id' })
    const showFilterRow = ref(true)
    const noDataText = ref('Tidak Ada Data')
    const autoExpandAll = ref(true)
    const displayMode = ref('full')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const dateOp = ref()
    const dateFilter = ref(null) as any
    const dateFilterTemporary = ref(null) as any
    const filters = reactive({
      // top: $numbers.totalItemPerPage,
      skip: 0,
      totalRecords: 0,
      search: sendInputSearch.value,
      // filter: `AgenTujuanId eq ${toAgentId.value} and StatusPengirimanId in (4,5)`,
      select: 'Id,Resi,StatusPengirimanId,CreatedDate,LastUpdate,TotalColly,HpPenerima,HpPengirim,NamaPengirim,NamaPenerima',
      expand: 'StatusPengiriman($select=Status,Keterangan,Attribute1,Attribute2),TransJadwalHd($select=KodeJadwal,TanggalAktif,Rit),AgenAsal($select=Nama),AgenTujuan($select=Nama)'
    }) as any

    const filterTanggal = () => {
      if (dateFilter.value !== null) {
        dateFilterTemporary.value = dateFilter.value
        if (!Array.isArray(dateFilterTemporary.value)) {
          dateFilterTemporary.value = [moment(), moment()]
        }
        return `CreatedDate gt ${moment(dateFilterTemporary.value[0]).format('YYYY-MM-DD')}T00:00:00Z and CreatedDate lt ${moment(dateFilterTemporary.value[1]).format('YYYY-MM-DD')}T23:59:59Z and`
      }
      return `CreatedDate gt ${moment().subtract(3, 'days').format('YYYY-MM-DD')}T00:00:00Z and CreatedDate lt ${moment().format('YYYY-MM-DD')}T23:59:59Z and`
    }

    const getAllData = async () => {
      loading.value = true
      filters.search = sendInputSearch.value
      filters.filter = `${filterTanggal()} AgenTujuanId eq ${toAgentId.value} and StatusPengirimanId in (5,6) and TransJadwalHd ne null`
      const response = await receiveItemUseCase.getAll(filters)

      if (!response.error) {
        data.value = response.result.map((x: any) => {
          // x.TotalKoli = x['TransPengirimanDetails@odata.count']
          x.Jadwal = x.TransJadwalHd ? x.TransJadwalHd.KodeJadwal : '-'
          x.NamaAgenTujuan = x.AgenTujuan ? x.AgenTujuan.Nama : '-'
          x.NamaAgenAsal = x.AgenAsal ? x.AgenAsal.Nama : '-'
          x.NamaStatusPengiriman = x.StatusPengiriman ? x.StatusPengiriman.Status : '-'
          x.TanggalJadwalAktif = x.TransJadwalHd.TanggalAktif
          x.Rit = x.TransJadwalHd.Rit
          return x
        })
        filters.totalRecords = response.count
      }
      loading.value = false
    }

    const submitDropAgent = (id: any) => {
      emit('submitDropAgent', id)
    }

    const submitSendCustomer = (id: any) => {
      emit('submitHandoverItem', id)
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getAllData()
    }

    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(refTableReceiveItem.value, 'Paket Diterima')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const saveFilterDate = (evt: any) => {
      dateOp.value.toggle(evt)
      filters.tanggalAktif = moment(dateFilter.value).format('YYYY-MM-DD')
      getAllData()
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      dateOp.value.hide()
      getAllData()
    }

    onMounted(() => {
      getAllData()
    })

    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }

    return {
      dt,
      data,
      loading,
      filters,
      type,
      moment,
      getAllData,
      onPage,
      submitSendCustomer,
      submitDropAgent,
      inputSearch,
      sendInputSearch,
      searchData,
      showHeaderFilter,
      showFilterRow,
      noDataText,
      autoExpandAll,
      exportGrids,
      refTableReceiveItem,
      position,
      showNavButtons,
      showInfo,
      showPageSizeSelector,
      pageSizes,
      displayMode,
      showDateFilter,
      saveFilterDate,
      clearFilterDate,
      dateOp,
      dateFilter,
    }
  }
}

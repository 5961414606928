import {
  ref, onMounted, computed, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import {
  dashboardUseCase
} from '@/domain/usecase'
import moment from 'moment'

const modules = 'dashboard'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataIncome = ref({})
    const deliveryTotal = computed(() => store.state[modules].deliveryTotal)
    const receiveTotal = computed(() => store.state[modules].receiveTotal)
    const claimTotal = computed(() => store.state[modules].claimTotal)
    const income = computed(() => store.state[modules].income)

    return {
      dataIncome,
      deliveryTotal,
      receiveTotal,
      claimTotal,
      income
    }
  }
}

import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'

export default {
  name: 'TableDeliveryPending',
  setup() {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers
    } = app!.appContext.config.globalProperties
    const op = ref()
    const Id = ref(null) as any
    const toAgentId = ref(store.getters['appActiveUser/getAgent']?.id)
    const loading = ref(false)
    const route = useRoute()
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const filters = {
      search: sendInputSearch.value,
      filter: `AgenAsalId eq ${toAgentId.value} and statusPengirimanId eq 2`,
      expand: '&$expand=TransPengirimanDetails($count=true;$select=id;$expand=KategoriBarang($select=isRefund)), AgenAsal($select=Id,Nama), AgenTujuan($select=Id,Nama)',
      select: '&$select=Id, ManifestId, TransJadwalHdId, JenisPembayaranId, TransPenerimaanId, AgenAsalId, AgenTujuanId, Resi, LastUpdate, CreatedDate, Attribute1',
      totalRecords: 0,
      top: 0,
      skip: 0
    }
    const isRefund = ref(false)
    const data = ref([])
    const showDialogDetail = ref(false)
    const dataForm = ref({})

    const getAllData = async () => {
      loading.value = true
      filters.search = sendInputSearch.value
      const response = await deliveryPendingUseCase.getAll(filters)
      data.value = response.result
      filters.totalRecords = response.count
      loading.value = false
      // store.dispatch('hideLoading')
    }

    const Search = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }

    const onDetail = (val: any) => {
      router.push({
        name: 'transaksi-pengiriman-pending-detail',
        params: {
          id: val
        }
      })
    }
    const showDropDown = (evt: any, item: any) => {
      // console.log(evt, item)
      dataForm.value = item
      op.value.toggle(evt)
    }

    const refund = (val: any) => {
      // console.log('id val', val)
      Id.value = val
      showDialogDetail.value = true
      isRefund.value = true
    }

    const detail = (val: any) => {
      Id.value = val
      showDialogDetail.value = true
      isRefund.value = false
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      // getAllData()
    }

    const hideDialog = () => {
      showDialogDetail.value = false
    }

    onMounted(() => {
      // store.dispatch('showLoading')
      getAllData()
    })
    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }
    return {
      store,
      filters,
      data,
      inputSearch,
      moment,
      onDetail,
      router,
      route,
      Search,
      showDialogDetail,
      op,
      showDropDown,
      isRefund,
      dataForm,
      refund,
      detail,
      hideDialog,
      getAllData,
      Id,
      onPage,
      loading,
      sendInputSearch,
      searchData
    }
  }
}

import {
  ref,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { TypePendingPackage } from '@/store/modules/dashboard/types'

const modules = 'dashboard'

export default {
  setup() {
    const store = useStore()
    const loading = ref(false)
    const router = useRouter()
    const data = ref([])
    const countPendingDelivery = computed<TypePendingPackage>(() => store.state[modules].pendingDelivery)

    const openManifest = () => {
      router.push({ name: 'transaksi-manifest-pengiriman' })
    }
    const goto = (refName: string) => {
      const element = document.getElementById(refName)
      if (element) {
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    }

    return {
      data,
      loading,
      openManifest,
      countPendingDelivery,
      goto
    }
  }
}

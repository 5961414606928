import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter'

class DownloadExcel {
  exportGridExcel = (ref: any, nameFile: string) => {
    const workbook = new Workbook()
    const priceSheet = workbook.addWorksheet(nameFile)
    // const ratingSheet = workbook.addWorksheet('Rating')

    // priceSheet.getRow(2).getCell(2).value = 'Price'
    // priceSheet.getRow(2).getCell(2).font = { bold: true, size: 16, underline: 'double' }

    // ratingSheet.getRow(2).getCell(2).value = 'Rating'
    // ratingSheet.getRow(2).getCell(2).font = { bold: true, size: 16, underline: 'double' }

    exportDataGrid({
      worksheet: priceSheet,
      component: ref.instance,
      // topLeftCell: { row: 4, column: 2 },
      autoFilterEnabled: true,
      // customizeCell(options) {
      //   const { gridCell, excelCell } = options
      //   if (gridCell?.rowType === 'data') {
      //     if (gridCell?.column?.dataField === 'Action') {
      //       excelCell.value = null
      //       excelCell.numFmt = '[<=9999999]###-####(###) ###-####'
      //     }
      //     if (gridCell?.column?.dataField === 'Website') {
      //       excelCell.value = { text: gridCell?.value, hyperlink: gridCell?.value }
      //       excelCell.font = { color: { argb: 'FF0000FF' }, underline: true }
      //       excelCell.alignment = { horizontal: 'left' }
      //     }
      //   }
      //   if (gridCell?.rowType === 'group') {
      //     excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } }
      //   }
      //   if (gridCell?.rowType === 'totalFooter' && excelCell.value) {
      //     excelCell.font.italic = true
      //   }
      // }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${nameFile}.xlsx`)
      })
    })
  }
}

const downloadExcel = new DownloadExcel()

export {
  downloadExcel
}